import React from "react";
import styles from "./Rig.module.css"
import classNames from "classnames";
import {FaLock} from "react-icons/fa6";

export function EmptyRig(props: {name?: string}): JSX.Element {
    return (
        <div className={classNames(styles.rig, styles.rig_empty)}>
            <div className={styles.emptyRigLogoContainer}>
                <FaLock/>
            </div>
            <div className={styles.rig__name}>{props.name}</div>
        </div>
    )
}
