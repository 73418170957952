import {updateStats} from "../stats/statsSlice";
import {SocketEventHandler} from "../socket/SocketMiddleware";
import {updateSettings, updateSettingsIfNeeded} from "../settings/settingsSlice";
import {RawServerData, RawServerSettings, RawServerTopData} from '../../model/RawServerData'
import {AppDispatch} from "../../app/store";
import {updateShopSettings} from "../shop/shopSlice";
import {updateRigs} from "../../app/slices/rigsSlice";
import {updateLocationData, updateLocationSettings} from '../../app/slices/locationSlice'
import {updateWalletData} from '../../app/slices/walletSlice'
import {updateLabData} from '../../app/slices/labSlice'
import {IsTMA} from '../../app/functions'
import {SocketResponseTypes} from '../../model/interfaces'
import {updateTopStats} from '../../app/slices/topSlice'

/**
 * See SocketMiddleware
 */
export const MinerDataEventHandler: SocketEventHandler = {
    eventType: SocketResponseTypes.DataResponse,
    handleEvent(data: RawServerData, dispatch: AppDispatch): void {
        if (Object.keys(data).length > 1) {
            dispatch(updateStats({data}))
            dispatch(updateRigs({data}))
            dispatch(updateWalletData({data}))
            dispatch(updateLabData({data}))
            dispatch(updateLocationData({data}))
            dispatch(updateSettingsIfNeeded())
        }

        if (data.alert) {
            return IsTMA() ? Telegram.WebApp.showAlert(data.alert) : alert(data.alert)
        }
    }
}

export const MinerSettingsEventHandler: SocketEventHandler = {
    eventType: SocketResponseTypes.SettingsResponse,
    handleEvent(settings: RawServerSettings, dispatch: AppDispatch): void {
        dispatch(updateSettings({settings}))
        dispatch(updateLocationSettings({settings}))
        dispatch(updateShopSettings({settings}))
    }
}

export const MinerTopStatsEventHandler: SocketEventHandler = {
    eventType: SocketResponseTypes.TopStatsResponse,
    handleEvent(topStats: RawServerTopData, dispatch: AppDispatch): void {
        dispatch(updateTopStats({topStats}))
    }
}

export const MinerBonusEventHandler: SocketEventHandler = {
    eventType: SocketResponseTypes.BonusResponse,
    handleEvent(data: RawServerData, dispatch: AppDispatch): void {
        if (Object.keys(data).length > 1) {
            dispatch(updateLabData({data}))

        }

        if (data.alert) {
            return IsTMA() ? Telegram.WebApp.showAlert(data.alert) : alert(data.alert)
        }
    }
}
