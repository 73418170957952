import {createSlice} from '@reduxjs/toolkit'
import {DataUpdateAction} from '../../model/types'
import {AppThunk} from '../store'
import {sendEvent} from '../../features/socket/socketSlice'
window.Buffer = Buffer

export interface LabState {
    lotteryButtonActive:    boolean
}

const initialState: LabState = {
    lotteryButtonActive: false
}

export const labSlice = createSlice({
    name: 'labSlice',
    initialState,
    reducers: {
        updateLabData: (state, action: DataUpdateAction) => {
            const data = action.payload.data
            state.lotteryButtonActive = +data.bonus_button === 1
            console.log('im in lab slice', data, state)
        }
    }
})

export const PlayLottery = (): AppThunk => async (dispatch) => {
    const eventName = `get_bonus`;

    dispatch(sendEvent({
        event: eventName,
        message: {}
    }))
}

export default labSlice.reducer
export const {updateLabData} = labSlice.actions
