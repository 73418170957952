import styles from "./Lab.module.css"

import React, {useEffect, useRef, useState} from 'react'
import {useAppDispatch, useAppSelector} from '../../../app/hooks'
import {RequestTopStats, TopStateUser} from '../../../app/slices/topSlice'
import {Button} from '../../../components/view/Button'
import {overclockRigs} from '../../../app/slices/rigsSlice'
import {PlayLottery} from '../../../app/slices/labSlice'
import {IsTMA} from '../../../app/functions'
import {AnimationDialog} from './AnimationDialog'

interface Content {
    [key: string]: {
        text: string
    }
}

function GetUserName(user: TopStateUser) {
    const invisibleSymbol = 'ᅠ ᅠ '

    if (!user.firstName.includes(invisibleSymbol) && user.firstName.trim()) return user.firstName.trim()
    if (user.username.trim()) return `@${user.username}`
    
    return 'unknown'
}

export default function Lab() {
    const dispatch = useAppDispatch()
    const data = useAppSelector((state) => state.lab)

    function lotteryButtonClicked() {
        if (lotteryButtonActive) {
            setLotteryAnimationActive(true)
            setTimeout(() => {
                setLotteryAnimationActive(false)
                dispatch(PlayLottery())
            }, 3000);
        } else {
            const msg = "Не стоит испытывать судьбу слишком часто, попробуйте завтра"
            IsTMA() ? Telegram.WebApp.showAlert(msg) : alert(msg)
        }
        setLotteryButtonActive(false)
    }

    function showCasinoClicked() {
        setLotteryAnimationActive(true)
        setTimeout(() => {
            setLotteryAnimationActive(false)
        }, 3000);
    }

    function adventureButtonClicked() {
        setAdventureAnimationActive(true)
        setTimeout(() => {
            setAdventureAnimationActive(false)
        }, 5000);
    }

    function robotButtonClicked() {
        setRobotAnimationActive(true)
        setTimeout(() => {
            setRobotAnimationActive(false)
        }, 10000);
    }


    const [lotteryButtonActive, setLotteryButtonActive] = useState<boolean>(data.lotteryButtonActive)
    const [lotteryAnimationActive, setLotteryAnimationActive] = useState<boolean>(false)
    const [adventureAnimationActive, setAdventureAnimationActive] = useState<boolean>(false)
    const [robotAnimationActive, setRobotAnimationActive] = useState<boolean>(false)

    return (
        <div>
            <div className={styles.rouletteButtonContainer}>
                <div className={styles.rouletteButtonDiv}>
                    <button className={styles.rouletteButton} onClick={() => lotteryButtonClicked()}>
                        Мне повезёт!
                    </button>
                </div>
            </div>

            <div className={styles.rouletteButtonContainer}>
                <div className={styles.rouletteButtonDiv}>
                    <button className={styles.rouletteButton} onClick={() => showCasinoClicked()}>
                        Показать казино
                    </button>
                </div>
            </div>

            <div className={styles.rouletteButtonContainer}>
                <div className={styles.rouletteButtonDiv}>
                    <button className={styles.rouletteButton} onClick={() => robotButtonClicked()}>
                        Показать робота
                    </button>
                </div>
            </div>

            <div className={styles.rouletteButtonContainer}>
                <div className={styles.rouletteButtonDiv}>
                    <button className={styles.rouletteButton} onClick={() => adventureButtonClicked()}>
                        Активировать радар
                    </button>
                </div>
            </div>


            {
                lotteryAnimationActive && <AnimationDialog url={'./lottery.gif'}/>
            }

            {
                adventureAnimationActive && <AnimationDialog url={'./radar2.gif'}/>
            }

            {
                robotAnimationActive && <AnimationDialog url={'./robot.gif'}/>
            }
        </div>
    )
}
