import styles from "./Stats.module.css";
import React, {useState} from "react";
import {HorizontalStatView, StatView} from "./StatView";
import {useAppSelector} from "../../../app/hooks";
import {DateChart} from "../../../components/charts/Chart";
import {ToTitleCase} from '../../../app/functions'

enum ChartType {
    Hash,
    Income
}

function rouletteButtonClicked() {
    console.log("rouletteButtonClicked")
}

export function Stats() {
    const [chartType, setChartType] = useState<ChartType>(ChartType.Income)

    const stats = useAppSelector((state) => state.stats)
    const settings = useAppSelector((state) => state.settings)

    const rigs = `${stats.totalRigs} / ${settings.rigsLimit}`
    const gpu = `${stats.gpu} / ${settings.gpuLimit}`
    // const noise = `${stats.noise}дБ`
    const dust = `${stats.dust} / 100`
    const temp = `${stats.temp}°C`
    const intensity = stats.intensity

    return (
        <div className={styles.stats}>
            <div className={styles.stats_top}>
                <div className={styles.stats__verticalList}>
                    <StatView title={"Локация"} text={ToTitleCase(settings.location)}/>
                    <StatView title={"Доход в час, E4"} text={stats.incomeE4}/>
                    <StatView title={"Доход в час, me4ton"} text={stats.income}/>
                    <StatView title={"Расход в час, nE4to"} text={stats.consumption}/>
                </div>
                <div className={styles.stats__chart}>
                    <div className={styles.stats__switch}>
                        <span onClick={() => setChartType(ChartType.Income)}
                              className={chartType === ChartType.Income ? styles.stats__switch_active : undefined}>Доход</span>
                        <span> | </span>
                        <span onClick={() => setChartType(ChartType.Hash)}
                              className={chartType === ChartType.Hash ? styles.stats__switch_active : undefined}>Хешрейт</span>
                    </div>

                    <div style={{flexGrow: 1}}>
                        <DateChart label={chartType === ChartType.Income ? "Доход" : "Хешрейт"}
                                   hideXAxisLabels={chartType === ChartType.Hash}
                                   data={chartType === ChartType.Hash ? stats.hashrateChart : stats.incomeChart}
                        />

                    </div>
                </div>
            </div>
            <div className={styles.stats__divider}/>
            <div className={styles.stats__bottomList}>
                <HorizontalStatView title={"Количество ригов"} text={rigs}/>
                <HorizontalStatView title={"Количество карт"} text={gpu}/>
                <HorizontalStatView title={"Хешрейт"} text={`${stats.hashrate} Mh/s`}/>
                <HorizontalStatView title={"Интенсивность"} text={`${intensity}%`}/>
                <HorizontalStatView title={"Температура"} text={temp}/>
                <HorizontalStatView title={"Запыленность"} text={dust}/>
            </div>

        </div>
    )
}
