import styles from "./AnimationDialog.module.css"
import React from "react"
import ReactPlayer from 'react-player'
// import {DealerView} from './DealerView'
// import {DealerItem} from '../model/DealerItem'

interface AnimationDialogProps {
    url: string
    // items: DealerItem[]
    // onSellItemToDealer: (item: string, count: number) => void
}

export function AnimationDialog(props: AnimationDialogProps) {
    const animation = document.getElementById("animation");

    return (
        <div className={styles.animationWrapper}>
            {/*<ReactPlayer className='animation' playing loop={true}  url={props.url} />*/}

            {/*<video preload="auto"*/}
            {/*       loop={true}*/}
            {/*       muted={true}*/}
            {/*       autoPlay={true}*/}
            {/*       playsInline={true}*/}
            {/*       className={styles.animation}*/}
            {/*       id={'animation'}*/}
            {/*       src={props.url}*/}
            {/*>*/}
            {/*</video>*/}
            <img className={styles.animation} src={props.url} />

    {/*{*/
    }
    {/*    props.items.map((item) => (*/
    }
    {/*        <DealerView key={item.id} item={item} onSellItemToDealer={props.onSellItemToDealer}></DealerView>*/
    }
    {/*    ))*/
    }
    {/*}*/}
        </div>
    )
}
