export interface Stock {
    mb:     number
    bp:     number
    cpu:    number
    gpu:    number
    wallet: number
}

export interface Vendors {
    shop:               number
    tradePlatform:      number
    tradePlatformChina: number
    used:               number
}

export enum SocketRequestTypes {
    GetData =       "get_data",
    GetSettings =   "get_setting",
    GetTopStats =   "get_stats",

    Withdraw =      "withdraw"
}

export enum SocketResponseTypes {
    DataResponse =      "send_data",
    SettingsResponse =  "send_setting",
    TopStatsResponse =  "send_stats",
    BonusResponse =     "send_bonus",
}

export interface CurrencyObject {
    E4?:                    number
    me4ton?:                number
    ne4to?:                 number
}
