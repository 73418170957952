import * as process from "process"

import {Action, configureStore, ThunkAction} from '@reduxjs/toolkit'
import socketMiddleware from "../features/socket/SocketMiddleware"

import {
    MinerBonusEventHandler,
    MinerDataEventHandler,
    MinerSettingsEventHandler,
    MinerTopStatsEventHandler,
} from '../features/miner/MinerDataEventHandler'

import statsReducer from '../features/stats/statsSlice'
import settingsReducer from '../features/settings/settingsSlice'
import shopReducer from '../features/shop/shopSlice'
import rigsReducer from './slices/rigsSlice'
import locationReducer from './slices/locationSlice'
import tonReducer from './slices/tonSlice'
import topStatsReducer from './slices/topSlice'
import walletReducer from './slices/walletSlice'
import labReducer from './slices/labSlice'

export const store = configureStore({
    reducer: {
        stats:      statsReducer,
        settings:   settingsReducer,
        shop:       shopReducer,
        rigs:       rigsReducer,
        wallet:     walletReducer,
        location:   locationReducer,
        ton:        tonReducer,
        topStats:   topStatsReducer,
        lab:        labReducer
    },
    middleware(getDefaultMiddleware) {
        return getDefaultMiddleware().concat([socketMiddleware(
            () => ({
                uri: (process.env.REACT_APP_SOCKET_URI || "") as string,
                id: (Telegram.WebApp.initDataUnsafe.user?.id || process.env.REACT_APP_DEFAULT_TEST_ID || "") as string,
                token: parseToken() || process.env.REACT_APP_DEFAULT_TOKEN || "",
                // tonwallet: 'UQCBEUohLGsAINT9WhCkdIM53TFP6TJ3QRyFFMHdYIFtamSl'
                // TODO: remove hardcoded address in prod
            }),
            [MinerDataEventHandler, MinerSettingsEventHandler, MinerTopStatsEventHandler, MinerBonusEventHandler]
        )]);
    },
});

const parseToken = () => {
    return document.location.href.split("?")[1]?.split("#")[0]
}

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
