import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RawServerData} from "../../model/RawServerData";
import {AppThunk} from "../store";
import {updateFarm} from "../../features/stats/statsSlice";
import {Stock} from '../../model/interfaces'

export interface RigsState {
    activeRigs: number
    totalRigs:  number
    stock: Stock
}

const initialState: RigsState = {
    activeRigs: 0,
    totalRigs:  0,
    stock: {
        mb:     0,
        bp:     0,
        cpu:    0,
        gpu:    0,
        wallet: 0
    }
};

type RigsUpdateAction = PayloadAction<{
    data: RawServerData;
}>

enum RigAction {
    BlowRigs = "set_pill",
    ToggleRigs = "set_pwr",
    OverclockRigs = "set_intensity"
}

export const rigsSlice = createSlice({
    name: 'rigsSlice',
    initialState,
    reducers: {
        updateRigs: (state, action: RigsUpdateAction) => {
            console.log('data', action.payload.data)
            state.activeRigs =  +action.payload.data.pwr
            state.totalRigs =   +action.payload.data.rigqty
            state.stock = {
                mb:     +action.payload.data.mb,
                bp:     +action.payload.data.bp,
                cpu:    +action.payload.data.cpu,
                gpu:    +action.payload.data.gpu,
                wallet: +action.payload.data.wallet
            }
        },
    }
});


export const toggleRigs = (amount = 1,): AppThunk => (dispatch, getState) => {
    dispatch(
        updateFarm(RigAction.ToggleRigs,
            (data) => ({pwr: +data.pwr + amount})
        )
    )
};

export const toggleAllRigs = (enable: boolean): AppThunk => (dispatch, getState) => {
    dispatch(
        updateFarm(RigAction.ToggleRigs,
            (data) => ({pwr: enable ? +data.rigqty : 0})
        )
    )
};

export const blowRigs = (): AppThunk => (dispatch, getState) => {
    dispatch(updateFarm(RigAction.BlowRigs, {pill: 0}))
};

export const overclockRigs = (intensity: number): AppThunk => (dispatch, getState) => {
    dispatch(
        updateFarm(RigAction.OverclockRigs,
            {intensity}))
};

export default rigsSlice.reducer;

export const {updateRigs} = rigsSlice.actions;
